import { Auth } from 'aws-amplify';
import { trackRouter } from 'vue-gtag-next';
import { createRouter, createWebHistory } from 'vue-router';
import constants from '@/config/constants';
import store from '@/store/index.js';
import { accessLog } from '@/common/accessLog';

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/user/Login.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/resetPasswordVerify',
    name: 'ResetPasswordVerify',
    component: () => import(/* webpackChunkName: "resetPasswordVerify" */ '../views/user/ResetPasswordVerify.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/resetPassword',
    name: 'ResetPassword',
    component: () => import(/* webpackChunkName: "resetPassword" */ '../views/user/ResetPassword.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/userRegister',
    name: 'UserRegister',
    redirect: '/userRegister/agreeTerms',
    component: () => import(/* webpackChunkName: "userRegister" */ '../views/user/UserRegister.vue'),
    meta: { requiresAuth: false },
    children: [
      {
        path: 'agreeTerms',
        name: 'AgreeTerms',
        component: () => import(/* webpackChunkName: "agreeTerms" */ '../views/AgreeTerms.vue'),
        meta: { requiresAuth: false }
      },
      {
        path: 'agreePrivacyPolicy',
        name: 'AgreePrivacyPolicy',
        component: () => import(/* webpackChunkName: "agreePrivacyPolicy" */ '../views/AgreePrivacyPolicy.vue'),
        meta: { requiresAuth: false }
      },
      {
        path: 'userInfo',
        name: 'UserInfo',
        component: () => import(/* webpackChunkName: "userInfo" */ '../views/user/UserInfo.vue'),
        meta: { requiresAuth: false }
      },
      {
        path: 'userInfoConfirmation',
        name: 'UserInfoConfirmation',
        component: () => import(/* webpackChunkName: "userInfoConfirmation" */ '../views/user/UserInfoConfirmation.vue'),
        meta: { requiresAuth: false }
      },
      {
        path: 'verify',
        name: 'Verify',
        component: () => import(/* webpackChunkName: "verify" */ '../views/user/Verify.vue'),
        meta: { requiresAuth: false }
      },
      {
        path: 'successSignUp',
        name: 'SuccessSignUp',
        component: () => import(/* webpackChunkName: "successSignUp" */ '../views/user/SuccessSignUp.vue'),
        meta: { requiresAuth: false }
      }
    ]
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import(/* webpackChunkName: "error" */ '../views/Error.vue'),
    meta: { requiresAuth: false }
  },
  {
    path: '/positiveeq',
    name: 'Positiveeq',
    redirect: '/resilienceeq/home',
    component: () => import(/* webpackChunkName: "Positiveeq" */ '../views/positiveeq/Positiveeq.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: 'first',
        name: 'PositiveeqFirstRegister',
        redirect: '/positiveeq/first/settings',
        component: () => import(/* webpackChunkName: "PositiveeqFirstRegister" */ '../views/positiveeq/PositiveeqFirstRegister.vue'),
        meta: { requiresAuth: true },
        children: [
          {
            path: 'settings',
            name: 'PositiveeqFirstSettings',
            component: () => import(/* webpackChunkName: "PositiveeqFirstSettings" */ '../views/positiveeq/PositiveeqFirstSettings.vue'),
            meta: { requiresAuth: true }
          }
        ]
      }
    ]
  },
  {
    path: '/resilienceeq',
    name: 'Resilienceeq',
    redirect: '/resilienceeq/home',
    component: () => import(/* webpackChunkName: "Resilienceeq" */ '../views/resilienceeq/Resilienceeq.vue'),
    meta: { requiresAuth: true },
    children: [
      {
        path: 'home',
        name: 'ResilienceeqHome',
        component: () => import(/* webpackChunkName: "ResilienceeqHome" */ '../views/resilienceeq/ResilienceeqHome.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'daily',
        name: 'ResilienceeqDailyRegister',
        redirect: '/resilienceeq/daily/factor',
        component: () => import(/* webpackChunkName: "ResilienceeqDailyRegister" */ '../views/resilienceeq/ResilienceeqDailyRegister.vue'),
        meta: { requiresAuth: true },
        children: [
          {
            path: 'factor',
            name: 'ResilienceeqDailyFactor',
            component: () => import(/* webpackChunkName: "ResilienceeqDailyFactor" */ '../views/resilienceeq/ResilienceeqDailyFactor.vue'),
            meta: { requiresAuth: true }
          },
          {
            path: 'complete',
            name: 'ResilienceeqDailyComplete',
            component: () => import(/* webpackChunkName: "ResilienceeqDailyComplete" */ '../views/resilienceeq/ResilienceeqDailyComplete.vue'),
            meta: { requiresAuth: true }
          }
        ]
      },
      {
        path: 'trainingLookBack',
        name: 'ResilienceeqTrainingLookBackRegister',
        redirect: '/resilienceeq/trainingLookBack/message',
        component: () =>
          import(/* webpackChunkName: "ResilienceeqTrainingLookBackRegister" */ '../views/resilienceeq/ResilienceeqTrainingLookBackRegister.vue'),
        meta: { requiresAuth: true },
        children: [
          {
            path: 'message',
            name: 'ResilienceeqTrainingLookBackMessage',
            component: () =>
              import(/* webpackChunkName: "ResilienceeqTrainingLookBackMessage" */ '../views/resilienceeq/ResilienceeqTrainingLookBackMessage.vue'),
            meta: { requiresAuth: true }
          },
          {
            path: 'goodPoints',
            name: 'ResilienceeqTrainingLookBackGoodPoints',
            component: () =>
              import(
                /* webpackChunkName: "ResilienceeqTrainingLookBackGoodPoints" */ '../views/resilienceeq/ResilienceeqTrainingLookBackGoodPoints.vue'
              ),
            meta: { requiresAuth: true }
          },
          {
            path: 'tagging',
            name: 'ResilienceeqTrainingLookBackTagging',
            component: () =>
              import(/* webpackChunkName: "ResilienceeqTrainingLookBackTagging" */ '../views/resilienceeq/ResilienceeqTrainingLookBackTagging.vue'),
            meta: { requiresAuth: true }
          },
          {
            path: 'goodPointsFactor',
            name: 'ResilienceeqTrainingLookBackGoodPointsFactor',
            component: () =>
              import(
                /* webpackChunkName: "ResilienceeqTrainingLookBackGoodPointsFactor" */ '../views/resilienceeq/ResilienceeqTrainingLookBackGoodPointsFactor.vue'
              ),
            meta: { requiresAuth: true }
          },
          {
            path: 'period',
            name: 'ResilienceeqTrainingLookBackPeriod',
            component: () =>
              import(/* webpackChunkName: "ResilienceeqTrainingLookBackPeriod" */ '../views/resilienceeq/ResilienceeqTrainingLookBackPeriod.vue'),
            meta: { requiresAuth: true }
          }
        ]
      },
      {
        path: 'trainingHistory',
        name: 'ResilienceeqTrainingHistory',
        component: () => import(/* webpackChunkName: "ResilienceeqTrainingHistory" */ '../views/resilienceeq/ResilienceeqTrainingHistory.vue'),
        meta: { requiresAuth: true }
      },
      {
        path: 'goodPointHistory',
        name: 'ResilienceeqGoodPointHistory',
        component: () => import(/* webpackChunkName: "ResilienceeqGoodPointHistory" */ '../views/resilienceeq/ResilienceeqGoodPointHistory.vue'),
        meta: { requiresAuth: true }
      }
    ]
  },
  { path: '/:pathMatch(.*)', redirect: '/resilienceeq/home' }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async to => {
  try {
    let isLogin = false;
    // ストアのユーザ情報の更新フラグ
    let isUserReload = true;

    // ストアのユーザIDを取得
    let uid = store.getters['user/user_id'];

    await Auth.currentAuthenticatedUser()
      .then(() => {
        isLogin = true;
      })
      .catch(() => {
        isLogin = false;
      });

    if (!isLogin && to.meta.requiresAuth) {
      // 未ログインで認証が必要な画面を表示しようとした場合、遷移先をログイン画面に書き換える
      return { name: 'Login' };
    } else if (isLogin && uid == null) {
      // ログイン済かつストアにユーザIDが無い場合、情報の取得を行う
      await store.dispatch('user/getRequest').then(() => {
        isUserReload = false;
      });
    }

    if (to.name === 'ResilienceeqHome') {
      // ユーザ情報の再読み込み処理
      if (isUserReload) {
        // ログインユーザのストアのユーザ情報を更新
        await store.dispatch('user/getRequest');
      }
    }
    // ユーザ利用履歴登録
    if (!constants.USER_USAGE_HISTORY_EXCLUSION_SCREEN_NAME_LIST.includes(to.name)) {
      // 対象外以外の画面に遷移する場合はユーザ利用履歴を登録する
      await postUserUsageHistory(to.name, isLogin);
    }
  } catch (error) {
    // 共通エラー処理
    return { name: 'Error' };
  }
});

/**
 * ユーザ利用履歴への登録処理
 * @param {*} screenName 画面名
 * @param {*} login ログイン有無
 */
const postUserUsageHistory = async function(screenName, login) {
  accessLog(screenName, null, null, null, 'view', null, login);
};

trackRouter(router);

export default router;
