import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

// modules
import chat from './modules/chat';
import chatComponent from './modules/chatComponent';
import user from './modules/user';
import chatLog from './modules/chatLog';
import tag from './modules/tag';
import tagFormComponent from './modules/tagFormComponent';
import history from './modules/history';
import eqChat from './modules/eqChat';
import eqChatComponent from './modules/eqChatComponent';
import eqChatLog from './modules/eqChatLog';
import positiveeq from './modules/positiveeq';

export default createStore({
  modules: {
    chat,
    chatComponent,
    user,
    chatLog,
    tag,
    tagFormComponent,
    history,
    eqChat,
    eqChatComponent,
    eqChatLog,
    positiveeq,
  },
  plugins: [
    createPersistedState({
      key: 'user',
      paths: ['user'],
      storage: window.sessionStorage
    }),
    createPersistedState({
      key: 'tag',
      paths: ['tag'],
      storage: window.sessionStorage
    })
  ]
});
