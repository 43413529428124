import { createI18n } from 'vue-i18n';

// デフォルトlocale
const locale = () => {
  const locale =
    (window.navigator.languages && window.navigator.languages[0]) ||
    window.navigator.language ||
    window.navigator.userLanguage ||
    window.navigator.browserLanguage;

  const locales = process.env.VUE_APP_I18N_LOCALES;
  return locales.includes(locale) ? locale : process.env.VUE_APP_I18N_LOCALE;
};

const loadMessages = () => {
  const locales = require.context('@/locales', true, /[\w-]+\.json$/i);
  return locales.keys().reduce((locs, loc) => ({ ...locs, [loc.replace(/\.|\/|json/g, '')]: locales(loc) }), {});
};

export const i18n = createI18n({
  locale: locale(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  messages: loadMessages()
});

export function setLocale(locale) {
  if (locale && process.env.VUE_APP_I18N_LOCALES.includes(locale)) {
    i18n.global.locale = locale;
  }
}
