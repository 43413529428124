import http from '@/common/http';
import constants from '@/config/constants';
import { isEmpty } from '@/common/validators';
import store from '../index';

const initialState = {
  clid: null,
  currentStep: null,
  nextStep: null,
  startStep: null,
  inputType: null,
  prevInputType: null,
  text: null,
  select: null,
  choice: null,
  messages: null,
  modal: null,
  isTermsAgree: false,
  isPrivacyPolicyAgree: false,
  requestNextStep: '',
  mode: null,
  callApi: null
};

export default {
  namespaced: true,
  state: {
    clid: null,
    startStep: null,
    currentStep: null,
    nextStep: null,
    inputType: null,
    prevInputType: null,
    text: null,
    select: null,
    choice: null,
    messages: null,
    modal: null,
    isTermsAgree: false,
    isPrivacyPolicyAgree: false,
    requestNextStep: '',
    mode: null,
    callApi: null
  },
  getters: {
    clid(state) {
      return state.clid;
    },
    startStep(state) {
      return state.startStep;
    },
    currentStep(state) {
      return state.currentStep;
    },
    nextStep(state) {
      return state.nextStep;
    },
    inputType(state) {
      return state.inputType;
    },
    prevInputType(state) {
      return state.prevInputType;
    },
    text(state) {
      return state.text;
    },
    select(state) {
      return state.select;
    },
    choice(state) {
      return state.choice;
    },
    messages(state) {
      return state.messages;
    },
    modal(state) {
      return state.modal;
    },
    isTermsAgree(state) {
      return state.isTermsAgree;
    },
    isPrivacyPolicyAgree(state) {
      return state.isPrivacyPolicyAgree;
    },
    mode(state) {
      return state.mode;
    },
    callApi(state) {
      return state.callApi;
    }
  },
  actions: {
    /**
     * 相談チャット応答処理
     * @param reqData リクエストデータ
     */
    async request({ rootState, commit, state }, reqData) {
      if (state.requestNextStep == reqData.nextStep) return;
      commit('setRequestNextStep', reqData.nextStep);
      return new Promise((resolve, reject) => {
        let ageToStoring = null;
        let birthYearToStoring = null;
        if (!isEmpty(reqData.age)) {
          ageToStoring = reqData.age.toString();
        }
        if (!isEmpty(rootState.user.birth_year)) {
          birthYearToStoring = rootState.user.birth_year.toString();
        }

        let params = {
          u_id: rootState.user.user_id,
          nickname: rootState.user.nickname,
          birth_year: birthYearToStoring,
          age: ageToStoring,
          age_range: reqData.ageRange,
          gender: rootState.user.gender,
          current_step: reqData.currentStep,
          next_step: reqData.nextStep,
          mode: state.mode,
          input_type: state.inputType,
          job: rootState.user.job,
          NoLoginAccess: true,
          call_api: state.call_api
        };
        if (!isEmpty(state.clid)) {
          params['cl_id'] = state.clid;
        }
        if (!isEmpty(reqData.input)) {
          params['input'] = reqData.input;
        }
        if (!isEmpty(reqData.message)) {
          params['message'] = reqData.message;
        }
        if (!isEmpty(reqData.tags)) {
          params['tags'] = reqData.tags;
        }
        if (!isEmpty(state.modal) && !isEmpty(state.modal.type)) {
          params['modal_type'] = state.modal.type;
        }
        if (!isEmpty(state.text) && !isEmpty(state.text.var)) {
          params['input_var'] = state.text.var;
        } else if (!isEmpty(state.select) && !isEmpty(state.select.var)) {
          params['input_var'] = state.select.var;
        }
        if (!isEmpty(rootState.user.tenant_id)) {
          params['tenant_id'] = rootState.user.tenant_id;
        }
        if (!isEmpty(rootState.user.tenant_code)) {
          params['tenant_code'] = rootState.user.tenant_code;
        }
        if (!isEmpty(rootState.user.residence)) {
          params['residence'] = rootState.user.residence;
        }
        if (!isEmpty(rootState.user.job_type)) {
          params['job_type'] = rootState.user.job_type;
        }
        if (!isEmpty(rootState.tag.tagKeyValues)) {
          params['formValue'] = rootState.tag.tagKeyValues;
        }
        http
          .post(constants.API_CHAT_URI, params)
          .then(response => {
            if (response) {
              commit('success', response.data);
              commit('setNextStep', response.data.next_step);
              commit('setCallApi', response.data.call_api);
            }
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    /**
     * Stateを初期化
     */
    destoryState({ commit }) {
      commit('destoryState');
    },
    /**
     * 次ステップ番号の設定
     */
    setNextStep({ commit }, nextStep) {
      commit('setNextStep', nextStep);
    },
    setPrevInputType({ commit }, inputType) {
      commit('setPrevInputType', inputType);
    },
    /**
     * モードの設定
     */
    setMode({ commit }, mode) {
      commit('setMode', mode);
    },
    /**
     * 入力項目名の設定
     */
    setInputVar({ commit }, inputVar) {
      commit('setInputVar', inputVar);
    }
  },
  mutations: {
    success(state, results) {
      if (isEmpty(results)) {
        return;
      }
      state.clid = results.cl_id;
      state.currentStep = results.current_step;
      state.inputType = results.input_type;
      state.text = results.text;
      state.select = results.select;
      state.choice = results.choice;
      state.messages = results.messages;
      state.modal = results.modal;
    },
    destoryState(state) {
      for (let key in state) {
        if (Object.prototype.hasOwnProperty.call(initialState, key)) {
          state[key] = initialState[key];
        }
      }
    },
    setStartStep(state, startStep) {
      state.startStep = startStep;
    },
    setNextStep(state, nextStep) {
      if (!Array.isArray(nextStep)) {
        state.nextStep = '';
        return;
      }

      // 通常シナリオステップ番号の正規表現
      const normalStepRegexp = new RegExp('^' + constants.STEP_FIRST_CHAR + constants.STEP_CONNECT_CHAR + '[0-9]{3}');

      // イントロシナリオステップ番号の正規表現
      const introStepRegexp = new RegExp(
        '^' + constants.STEP_FIRST_CHAR + constants.STEP_CONNECT_CHAR + constants.STEP_INTRO_CHAR + constants.STEP_CONNECT_CHAR + '[0-9]{3}'
      );

      // 次ステップ番号の先頭が通常シナリオステップ番号の場合
      if (!isEmpty(nextStep[0].match(normalStepRegexp))) {
        state.nextStep = nextStep[0];
        return;
      }

      // 次ステップ番号の先頭がイントロシナリオステップ番号の場合
      if (!isEmpty(nextStep[0].match(introStepRegexp))) {
        state.nextStep = nextStep[0];
        return;
      }

      // 次ステップ番号にタグ専用シナリオステップ番号が含まれる場合
      let tags = store.getters['tag/tags'];
      for (let step of nextStep) {
        for (let tag of tags) {
          let tagStep = constants.STEP_FIRST_CHAR + constants.STEP_CONNECT_CHAR + tag + constants.STEP_CONNECT_CHAR;
          // ストアのタグ情報と前方一致する場合
          if (step.startsWith(tagStep)) {
            state.nextStep = step;
            return;
          }
        }
      }
      // タグ専用シナリオステップ番号の中に一致するタグが存在しない場合
      let lastNextStep = nextStep[nextStep.length - 1];
      // 次ステップ番号の最終シナリオステップ番号が通常シナリオの場合
      if (lastNextStep.match(normalStepRegexp)) {
        state.nextStep = lastNextStep;
      } else if (Object.values(constants.RESERVED_STEP).includes(lastNextStep)) {
        // 次ステップ番号の最終が予約ステップ番号の場合
        state.nextStep = lastNextStep;
      } else {
        // 次ステップ番号の最終がタグ専用シナリオステップ番号の場合
        state.nextStep = '';
      }

      // 相談窓口専用シナリオステップ番号が含まれる場合
      let consultationStep = constants.STEP_FIRST_CHAR + constants.STEP_CONNECT_CHAR + constants.STEP_CONSULTATION + constants.STEP_CONNECT_CHAR;
      if (nextStep[0].startsWith(consultationStep)) {
        state.nextStep = nextStep[0];
        return;
      }
    },
    setTermsAgree(state, isTermsAgree) {
      state.isTermsAgree = isTermsAgree;
    },
    setPrivacyPolicyAgree(state, isPrivacyPolicyAgree) {
      state.isPrivacyPolicyAgree = isPrivacyPolicyAgree;
    },
    setRequestNextStep(state, nextStep) {
      state.requestNextStep = nextStep;
    },
    setMode(state, mode) {
      state.mode = mode;
    },
    setInputVar(state, inputVar) {
      if (!isEmpty(state.text) && !isEmpty(state.text.var)) {
        state.text.var = inputVar;
      }
    },
    setPrevInputType(state, inputType) {
      state.prevInputType = inputType;
    },
    setCallApi(state, callApi) {
      state.call_api = callApi;
    }
  }
};
