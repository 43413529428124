<template>
  <router-view />
</template>

<script>
export default {
  name: 'App',
  mounted() {
    document.documentElement.setAttribute('lang', this.$i18n.locale);
    document.title = this.$t('title');
  }
};
</script>

<style>
#app {
  margin: 0;
  height: 100%;
}
</style>
