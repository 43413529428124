import { i18n } from '@/plugin/i18n';
import { isEmpty } from '@/common/validators';
const json = require('@/config/message/' + i18n.global.locale + '.json');

/**
 * メッセージを取得する
 * @param cd メッセージコード
 * @param bindStrings バインド文字配列（任意）
 * @return バインド後のメッセージ
 */
export function getMessage(cd, binds) {
  const msgJson = JSON.parse(JSON.stringify(json));
  var message = '';
  if (msgJson[cd] != undefined) {
    message = msgJson[cd];
    if (!isEmpty(binds)) {
      for (let i in binds) {
        message = message.replace('[' + i + ']', binds[i]);
      }
    }
  }
  return message;
}
