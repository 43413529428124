import store from '../index';
import { i18n } from '@/plugin/i18n';

const initialState = {
  components: []
};

export default {
  namespaced: true,
  state: {
    components: []
  },
  getters: {
    components(state) {
      return state.components;
    }
  },
  actions: {
    /**
     * コンポーネント追加
     */
    async addComponent({ dispatch }) {
      // タグフォーム取得処理
      await dispatch('tag/getTagFormRequest', null, { root: true })
      .then(() => {
        let tagForms = store.getters['tag/tagForms'];
        for (let tagForm of tagForms) {
          switch (tagForm.type) {
            case 'select':
              this.dispatch('tagFormComponent/addSelectBoxComponent', tagForm);
              break;
          }
        }
      })
      .catch(error => {
        console.error('[src/store/modules/tagFormComponent][addComponent]タグフォーム取得処理エラー：', error);
        return Promise.reject(error);
      });
    },
    /**
     * コンポーネント追加（未回答）
     */
    async addUnRegistComponent({ dispatch }) {
      // タグフォーム取得処理
      await dispatch('tag/getUnRegistTagFormRequest', null, { root: true })
        .then(() => {
          let tagForms = store.getters['tag/tagForms'];
          // 表示しているテナント名
          let lastTenantName = '';
          //  テナント数
          let tenantCount = 0;
          for (let tagForm of tagForms) {

            if (tagForm.tenantName != lastTenantName) {
              // 表示するテナントが切り替わった場合

              tenantCount++;
              if (tenantCount > 1) {
                // テナントが一件目以降はボーダーラインを表示
                this.dispatch('tagFormComponent/addBorderComponent');
              }
              // テナント名、プラン名を表示
              this.dispatch('tagFormComponent/addTenantHeaderComponent', tagForm);
              lastTenantName = tagForm.tenantName;
            }
            switch (tagForm.type) {
              case 'select':
                this.dispatch('tagFormComponent/addSelectBoxLineSpaceComponent', tagForm);
                break;
            }
          }
        })
        .catch(error => {
          console.error('[src/store/modules/tagFormComponent][addComponent]タグフォーム取得処理エラー：', error);
          return Promise.reject(error);
        });
    },
    /**
     * コンポーネント追加（プロフィール編集画面用）
     */
    async addProfileComponent({ dispatch }) {
      // タグフォーム取得処理
      await dispatch('tag/getTagFormRequest', null, { root: true })
        .then(() => {
          let tagForms = store.getters['tag/tagForms'];
          for (let tagForm of tagForms) {
            switch (tagForm.type) {
              case 'select':
                this.dispatch('tagFormComponent/addSelectBoxLineSpaceComponent', tagForm);
                break;
            }
          }
        })
        .catch(error => {
          console.error('[src/store/modules/tagFormComponent][addComponent]タグフォーム取得処理エラー：', error);
          return Promise.reject(error);
        });
    },
    /**
     * セレクトボックスコンポーネントを追加
     * @param tagForm タグフォーム情報
     */
    addSelectBoxComponent({ commit }, tagForm) {
      let component = {
        component: 'SelectBox',
        name: tagForm.id,
        type: tagForm.type,
        label: tagForm.label,
        placeholder: i18n.global.t('form.select.placeholder'),
        rules: tagForm.required ? 'required' : '',
        options: tagForm.values
      };
      commit('addComponent', component);
    },
    /**
     * セレクトボックス（行間あり）コンポーネントを追加
     * @param tagForm タグフォーム情報
     */
    addSelectBoxLineSpaceComponent({ commit }, tagForm) {
      let component = {
        component: 'SelectBoxLineSpace',
        name: tagForm.id,
        type: tagForm.type,
        label: tagForm.label,
        placeholder: i18n.global.t('form.select.placeholder'),
        rules: tagForm.required ? 'required' : '',
        options: tagForm.values
      };
      commit('addComponent', component);
    },
    /**
     * ボーダーコンポーネントを追加
     */
    addBorderComponent({ commit }) {
      let component = {
        component: 'Border',
        name: 'border'
      };
      commit('addComponent', component);
    },
    /**
     * テナントヘッダーコンポーネントを追加
     * @param tagForm タグフォーム情報
     */
    addTenantHeaderComponent({ commit }, tagForm) {
      let option = {
        tenantName: tagForm.tenantName,
        planName: tagForm.planName
      };

      let component = {
        component: 'TenantHeader',
        name: 'header_' + tagForm.tenantName,
        options: option,
      };
      commit('addComponent', component);
    },
    /**
     * Stateを初期化
     */
    destoryState({ commit }) {
      commit('destoryState');
    }
  },
  mutations: {
    addComponent(state, component) {
      state.components.push(component);
    },
    destoryState(state) {
      for (let key in state) {
        if (Object.prototype.hasOwnProperty.call(initialState, key)) {
          if (Array.isArray(state[key])) {
            state[key].length = initialState[key].length;
          } else {
            state[key] = initialState[key];
          }
        }
      }
    }
  }
};
