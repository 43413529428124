import http from '@/common/http';
import constants from '@/config/constants';
import { isEmpty } from '@/common/validators';
import store from '../index';

export default {
  namespaced: true,
  state: {
    tags: [],
    tagKeys: [],
    tagKeyValues: [],
    tagForms: []
  },
  getters: {
    tags(state) {
      return state.tags;
    },
    tagKeys(state) {
      return state.tagKeys;
    },
    tagKeyValues(state) {
      return state.tagKeyValues;
    },
    tagForms(state) {
      return state.tagForms;
    }
  },
  actions: {
    /**
     * タグフォーム取得処理
     */
    async getTagFormRequest({ commit }) {
      let param = {
        NoLoginAccess: true
      };
      param.tenantIdList = store.getters['user/tenant_id'];
      return new Promise((resolve, reject) => {
        http
          .post(constants.API_TENANT_FORM_URI, param)
          .then(response => {
            if (response) {
              commit('setTagForms', response.data.tag_forms);
            }
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    /**
     * タグフォーム(未回答)取得処理
     */
    async getUnRegistTagFormRequest({ commit }) {
      return new Promise((resolve, reject) => {
        http
          .post(constants.API_TENANT_FORM_URI + '/unregistered')
          .then(response => {
            if (response) {
              commit('setTagForms', response.data.tag_forms);
            }
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    /**
     * タグ情報の追加
     * @param tags タグ情報（配列、または、カンマ区切りの文字列）
     */
    addTags({ commit }, tags) {
      if (isEmpty(tags)) return;

      if (typeof tags == 'string') {
        commit('addTags', tags.split(','));
        return;
      }
      commit('addTags', tags);
    },
    /**
     * タグキー情報の追加
     * @param tagKeys タグキー情報（配列、または、カンマ区切りの文字列）
     */
    addTagKeys({ commit }, tagKeys) {
      if (isEmpty(tagKeys)) return;

      let tagKeysArray;
      if (typeof tagKeys == 'string') {
        tagKeysArray = tagKeys.split(',');
      } else {
        tagKeysArray = tagKeys;
      }
      commit('addTagKeys', tagKeysArray);
    },
    /**
     * タグキー＆値情報を元にタグキー情報を追加
     * @param tagKeyValues タグキー＆値情報（配列、または、カンマ区切りの文字列）
     */
    addTagKeysByKeyValues({ commit }, tagKeyValues) {
      if (isEmpty(tagKeyValues)) return;

      let tagKeys = [];
      for (let tagKeyValue of tagKeyValues) {
        tagKeys.push(tagKeyValue.key);
      }
      commit('addTagKeys', tagKeys);
    },
    /**
     * タグキー情報を元に、タグキー＆値情報の初期値追加
     * @param tagKeys タグキー情報（配列、または、カンマ区切りの文字列）
     */
    addInitTagKeyValues({ commit }, tagKeys) {
      if (isEmpty(tagKeys)) return;

      let tagKeysArray;
      if (typeof tagKeys == 'string') {
        tagKeysArray = tagKeys.split(',');
      } else {
        tagKeysArray = tagKeys;
      }

      let tagKeyValues = [];
      for (let tagKey of tagKeysArray) {
        tagKeyValues.push({ key: tagKey, value: '' });
      }
      commit('addTagKeyValues', tagKeyValues);
    },
    /**
     * タグキー＆値情報の追加
     * @param tagKeyValues タグキー＆値情報（配列、または、カンマ区切りの文字列）
     */
    addTagKeyValues({ commit }, tagKeyValues) {
      if (isEmpty(tagKeyValues)) return;
      commit('addTagKeyValues', tagKeyValues);
    },
    /**
     * タグキー＆値情報の初期化
     */
    clearTagKeyValues({ commit, state }) {
      let tagKeyValues = [];
      for (let tagKey of state.tagKeys) {
        tagKeyValues.push({ key: tagKey, value: '' });
      }
      commit('setTagKeyValues', tagKeyValues);
    },
    /**
     * タグフォームの初期化
     */
    clearTagForms({ commit, state }) {
      let tagForms = [];
      for (let tagForm of state.tagForms) {
        tagForms.push({ key: tagForm, value: '' });
      }
      commit('setTagForms', tagForms);
    },
    setTagKeyValues({ commit }, tagKeyValues) {
      commit('setTagKeyValues', tagKeyValues);
    }
  },
  mutations: {
    addTags(state, tags) {
      state.tags = [...new Set([...state.tags, ...tags])];
    },
    addTagKeys(state, tagKeys) {
      state.tagKeys = [...new Set([...state.tagKeys, ...tagKeys])];
    },
    addTagKeyValues(state, tagKeyValues) {
      let newTagKeyValues = state.tagKeyValues;
      for (let tagKeyValue of tagKeyValues) {
        // 重複は除く
        let result = newTagKeyValues.find(({ key }) => key === tagKeyValue.key);
        if (isEmpty(result)) {
          newTagKeyValues.push(tagKeyValue);
        }
      }
      state.tagKeyValues = newTagKeyValues;
    },
    setTagKeyValues(state, tagKeyValues) {
      state.tagKeyValues = tagKeyValues;
    },
    setTagForms(state, tagForms) {
      state.tagForms = tagForms;
    }
  }
};
