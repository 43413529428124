export function isEmpty(value) {
  if (!value) {
    if (value !== 0 && value !== false) {
      return true;
    }
  } else if (typeof value === 'object') {
    return Object.keys(value).length === 0;
  }
  return false;
}

export function isMaxLength(value, max) {
  if (!value) return true;
  if (typeof value === 'string' && value.length <= max) return true;
  return false;
}

export function isMinLength(value, min) {
  if (!value) return true;
  if (typeof value === 'string' && value.length >= min) return true;
  return false;
}
