const initialState = {
  summaries: {},
  selectValues: {}
};

export default {
  namespaced: true,
  state: {
    summaries: {},
    selectValues: {}
  },
  getters: {
    summaries(state) {
      return state.summaries;
    },
    selectValues(state) {
      return state.selectValues;
    }
  },
  actions: {
    addSummary({ commit }, summary) {
      commit('addSummary', summary);
    },
    addSelectValue({ commit }, { key, value }) {
      commit('addSelectValue', { key, value });
    },
    /**
     * Stateを初期化
     */
    destoryState({ commit }) {
      commit('destoryState');
    }
  },
  mutations: {
    addSummary(state, summary) {
      state.summaries = Object.assign(state.summaries, summary);
    },
    addSelectValue(state, { key, value }) {
      // 既にオブジェクトが存在しているか
      if (Object.prototype.hasOwnProperty.call(state.selectValues, key)) {
        // 配列にデータを追加
        state.selectValues[key].push(value);
      } else {
        // オブジェクトを追加（選択肢の値は配列で保持）
        state.selectValues[key] = [value];
      }
    },
    destoryState(state) {
      for (let key in state) {
        if (Object.prototype.hasOwnProperty.call(initialState, key)) {
          if (Array.isArray(state[key])) {
            delete state[key];
          } else if (state[key] !== null && typeof state[key] === 'object') {
            state[key] = {};
          } else {
            state[key] = initialState[key];
          }
        }
      }
    }
  }
};
