import { i18n } from '@/plugin/i18n';

/**
 * 主に環境変数の値など、システム全体で利用する定数です。
 */
export default Object.freeze({
  // アプリケーションドメイン
  APP_DOMAIN: process.env.VUE_APP_DOMAIN,

  // アプリケーションURL
  APP_URL: process.env.VUE_APP_URL,

  // API ベースURL
  API_BASE_URL: process.env.VUE_APP_API_BASE_URL,

  // API キー
  API_KEY: process.env.VUE_APP_API_KEY,

  // Cognito REGION
  COGNITO_REGION: process.env.VUE_APP_COGNITO_REGION,

  // Cognito IDPOOL_ID
  COGNITO_IDPOOL_ID: process.env.VUE_APP_COGNITO_IDPOOL_ID,

  // Cognito USERPOOL_ID
  COGNITO_USERPOOL_ID: process.env.VUE_APP_COGNITO_USERPOOL_ID,

  // Cognito CLIENT_ID
  COGNITO_CLIENT_ID: process.env.VUE_APP_COGNITO_CLIENT_ID,

  // API ユーザー登録・更新・取得処理URL
  API_NOLOGINUSER_URI: '/' + process.env.VUE_APP_API_STAGE + '/nologinuser',

  // API サインアップ
  API_USERS_SIGNUP_URI: '/' + process.env.VUE_APP_API_STAGE + '/user/signup',

  // API ユーザ情報登録
  API_USERS_REGIST_USER_URI: '/' + process.env.VUE_APP_API_STAGE + '/user/registuser',

  // API メールアドレス変更
  API_USERS_UPDATE_EMAIL_URI: '/' + process.env.VUE_APP_API_STAGE + '/user/updateemail',

  // API メールアドレス変更検証
  API_USERS_VERIFY_EMAIL_URI: '/' + process.env.VUE_APP_API_STAGE + '/user/verifyemail',

  // API プロフィール参照
  API_USER_REFERENCE: '/' + process.env.VUE_APP_API_STAGE + '/user/userreference',

  // API プロフィール更新
  API_USER_MAINTENANCE: '/' + process.env.VUE_APP_API_STAGE + '/user/usermaintenance',

  // API ユーザ退会処理
  API_USER_DELETE_ACCOUNT: '/' + process.env.VUE_APP_API_STAGE + '/user/deleteaccount',

  // API ユーザプラン追加申請処理URL
  API_USER_PLAN_ADDITION_URI: '/' + process.env.VUE_APP_API_STAGE + '/user/planapplication',

  // API ユーザプラン自動紐づけ処理URL
  API_USER_REGIST_PLAN_URI: '/' + process.env.VUE_APP_API_STAGE + '/user/registplan',

  // API ユーザプラン解除処理
  API_USER_UNREGIST_PLAN: '/' + process.env.VUE_APP_API_STAGE + '/user/unregistplan',

  // API サービス利用状況一覧取得処理
  API_USER_SERVICE_STATUS: '/' + process.env.VUE_APP_API_STAGE + '/user/servicestatus',

  // API 通知情報取得
  API_HOME_URI: '/' + process.env.VUE_APP_API_STAGE + '/homeInfo',

  // API 相談チャット応答処理URL
  API_CHAT_URI: '/' + process.env.VUE_APP_API_STAGE + '/chat',

  // API EQ相談チャット応答処理URL
  API_EQCHAT_URI: '/' + process.env.VUE_APP_API_STAGE + '/eqchat',

  // チャット履歴一覧取得処理URL
  API_CHAT_HISTORY_URI: '/' + process.env.VUE_APP_API_STAGE + '/chatHistory',

  // チャット詳細取得処理URL
  API_CHAT_DETAIL_URI: '/' + process.env.VUE_APP_API_STAGE + '/chat',

  // 会員に関する規約取得処理URL
  API_TERMS_USERS_URI: '/' + process.env.VUE_APP_API_STAGE + '/terms/users',

  // 個人に関する規約取得処理URL
  API_TERMS_PERSONAL_URI: '/' + process.env.VUE_APP_API_STAGE + '/terms/personal',

  // プランに関する規約取得処理URL
  API_TERMS_PLAN_URI: '/' + process.env.VUE_APP_API_STAGE + '/terms/plan',

  // 合意済みの会員に関する規約取得処理URL
  API_TERMS_AGREED_USERS_URI: '/' + process.env.VUE_APP_API_STAGE + '/terms/agreed/users',

  // 合意済みの個人に関する規約取得処理URL
  API_TERMS_AGREED_PERSONAL_URI: '/' + process.env.VUE_APP_API_STAGE + '/terms/agreed/personal',

  // 会員に関する規約の合意情報登録処理URL
  API_TERMS_HISTORY_USERS_URI: '/' + process.env.VUE_APP_API_STAGE + '/termsHistory/users',

  // 個人に関する規約の合意情報登録処理URL
  API_TERMS_HISTORY_PERSONAL_URI: '/' + process.env.VUE_APP_API_STAGE + '/termsHistory/personal',

  // ノーログインユーザーに関する規約取得処理URL
  API_TERMS_NO_LOGIN_USERS_URI: '/' + process.env.VUE_APP_API_STAGE + '/terms/noLoginUsers',

  // 個人情報（ノーログイン）に関する規約取得処理URL
  API_TERMS_PERSONAL_NO_LOGIN_URI: '/' + process.env.VUE_APP_API_STAGE + '/terms/personalNoLogin',

  // プラン（ノーログイン）に関する規約取得処理URL
  API_TERMS_PLAN_NO_LOGIN_URI: '/' + process.env.VUE_APP_API_STAGE + '/terms/planNologin',

  // ユーザ利用履歴登録処理URL
  API_USER_USAGE_HISTORY_URI: '/' + process.env.VUE_APP_API_STAGE + '/user/userusagehistory',

  // 相談窓口情報処理URL
  API_USER_CONSULTATION_URI: '/' + process.env.VUE_APP_API_STAGE + '/user/consultation',

  // 記事取得処理URL
  API_COLUMN_URI: '/' + process.env.VUE_APP_API_STAGE + '/user/column',

  // リンク情報処理URL
  API_USER_LINKS_URI: '/' + process.env.VUE_APP_API_STAGE + '/user/links',

  // API タグ暗号化・複合化処理URL
  API_TAGS_URI: '/' + process.env.VUE_APP_API_STAGE + '/user/tags',

  // API 拡張属性取得・登録処理URL
  API_TENANT_FORM_URI: '/' + process.env.VUE_APP_API_STAGE + '/user/tenantForm',

  // APIエラー時のリトライ回数
  API_LIMIT_RETRY_COUNT: 1,

  // APIタイムアウト値（ミリ秒）
  API_TIMEOUT: 29000,

  // チャットステップ番号
  STEP_FIRST_CHAR: 'step',
  STEP_CONNECT_CHAR: '_',
  SODAN_STEP_START: 'step_001',
  CHAT_STEP_START: 'step_300',
  STEP_INTRO_CHAR: 'intro',
  INTRO_STEP_START: 'step_intro_001',
  INTRO_STEP_EQ_START: 'step_intro_EQ_001',
  STEP_CONSULTATION: 'CONSULTATION',

  // EQチャット用
  STEP_INTRO_EQ_CHAR: 'EQ',
  NOTAG_FIRST_CHAR: 'NOTAG',

  RESERVED_STEP: {
    start: 'step_start',
    end: 'step_end',
    top: 'step_top',
    columnConsultation: 'step_column_consultation',
    onlineConsultation: 'step_online_consultation',
    kokorobo: 'step_kokorobo',
    // EQチャット用
    end_eq: 'step_EQ_end',
    top_eq: 'step_EQ_top'
  },
  RESERVED_STEP_LINK_URL: {
    step_top: '/',
    // EQチャット用
    step_EQ_top: '/',
    step_column_consultation: '/column-consultation',
    step_online_consultation: 'https://www.kokorobo.jp/guide/online-sodan/',
    step_kokorobo: 'https://epro-kokorobo.jp/epro/from_cocoro_conditioner?show_counselor_page=0'
  },

  // チャットメッセージの置換メタ文字 開始・終了
  CHAT_MSG_REP_META_CHAR_START: '<',
  CHAT_MSG_REP_META_CHAR_END: '>',
  CHAT_MSG_REP_NAME: '<NAME>',

  // ニックネーム 最大文字数
  NICKNAME_MAX_NUM: 100,

  // メールアドレス 最大文字数
  EMAIL_ADDRESS_MAX_NUM: 254,

  // パスワード 最小文字数
  PASSWORD_MIN_NUM: 8,

  // パスワード 最大文字数
  PASSWORD_MAX_NUM: 256,

  // 確認コード 最大文字数
  VERIFY_CODE_MAX_NUM: 10,

  // チャット入力欄 最大文字数
  CHAT_TEXT_MAX_NUM: 1000,

  // テナントコード 最大文字数
  TENANT_CODE_MAX_NUM: 255,

  // 特定情報 最大文字数
  SPEC_INFO_MAX_NUM: 100,

  // 研究ID 最大文字数
  RESEARCH_ID_MAX_NUM: 6,

  // コラムID情報
  COLUMN_ID_INFO: ['about', 'consultation', 'column1', 'column2', 'column3'],

  // Google Analytics
  GOOGLE_ANALYTICS_TRACKING_ID: 'G-NKTSM0H8VX',
  GOOGLE_ANALYTICS_TARGET_DOMAINS: [process.env.VUE_APP_DOMAIN],

  // 利用規約・個人情報の取り扱い
  TERMS_HTML_PATH: './terms/terms_' + i18n.global.locale + '.html',
  PRIVACY_POLICY_HTML_PATH: './terms/privacyPolicy_' + i18n.global.locale + '.html',

  // モード
  MODE_SODAN: '相談',
  MODE_CHAT: 'チャット',
  MODE_JUJITSU: '充実',
  MODE_INTRO: 'イントロ',

  // 振り返り・タグキー（出来事）
  EVENT_KEY: ['EVENT'],
  FEEL_KEY: ['FEEL'],
  CHALLENGE_KEY: ['CHALLENGE'],
  CHAT_KEY: ['CHAT'],
  ACT_KEY: ['ACT'],
  ACT_FIRST_KEY: ['ACT_FIRST'],
  ACT_WHERE_KEY: ['ACT_WHERE'],
  ACT_TIME_KEY: ['ACT_TIME'],

  // 間を取る処理
  TEXT_THRESHOID: 10,
  TEXT_DELAY_UNDER_TIME: 2000,
  TEXT_DELAY_OVER_TIME: 3000,
  SELECT_DELAY_TIME: 1000,
  CHOICE_DELAY_TIME: 1000,

  // ユーザ情報 性別セレクトボックス
  USER_INFO_GENDER_OPTIONS: ['male', 'female', 'other', 'nothing'],

  // ユーザ情報 都道府県セレクトボックス
  USER_INFO_RESIDENCE_OPTIONS: [
    'hokkaido',
    'aomori',
    'iwate',
    'miyagi',
    'akita',
    'yamagata',
    'fukushima',
    'ibaraki',
    'tochigi',
    'gunma',
    'saitama',
    'chiba',
    'tokyo',
    'kanagawa',
    'niigata',
    'toyama',
    'ishikawa',
    'fukui',
    'yamanashi',
    'nagano',
    'gifu',
    'shizuoka',
    'aichi',
    'mie',
    'shiga',
    'kyoto',
    'osaka',
    'hyogo',
    'nara',
    'wakayama',
    'tottori',
    'shimane',
    'okayama',
    'hiroshima',
    'yamaguchi',
    'tokushima',
    'kagawa',
    'ehime',
    'kochi',
    'fukuoka',
    'saga',
    'nagasaki',
    'kumamoto',
    'oita',
    'miyazaki',
    'kagoshima',
    'okinawa',
    'other'
  ],

  // ユーザ情報 職業セレクトボックス
  USER_INFO_JOB_OPTIONS: [
    'kaisha_hikanri',
    'kaisha_kanri',
    'kaisha_keiei',
    'kaisha_hiseiki',
    'koumuin',
    'jieigyo',
    'iryou',
    'gakusei',
    'mushoku',
    'sonota'
  ],

  // ユーザ情報 職種セレクトボックス
  USER_INFO_JOBTYPE_OPTIONS: [
    'eigyo',
    'jimu',
    'kikaku',
    'service',
    'web',
    'creative',
    'senmon',
    'tech_soft',
    'tech_denki',
    'tech_sozai',
    'tech_medical',
    'tech_doboku',
    'tech_setsubi',
    'sonota'
  ],

  // 記事カテゴリ 知りたい
  COLUMN_CATEGORY_SIRITAI: '0',

  // 記事カテゴリ 基礎
  COLUMN_CATEGORY_KISO: '1',

  // 記事カテゴリ 実践
  COLUMN_CATEGORY_ZISSEN: '2',

  // ドメイン名ラベル最大文字数
  DOMAIN_LABEL_MAX_NUM: '63',

  // ユーザー利用履歴登録対象外の画面
  USER_USAGE_HISTORY_EXCLUSION_SCREEN_NAME_LIST: [
    'Login',
    'ResetPasswordVerify',
    'ResetPassword',
    'UserRegister',
    'AgreeTerms',
    'AgreePrivacyPolicy',
    'UserInfo',
    'UserInfoConfirmation',
    'Verify',
    'UserRegisterPlanApplication',
    'SuccessSignUp',
    'Error'
  ],

  // EQチャット用コラム
  CHAT_COLUMN_BASE_PATH: '/chatColumn/'
});
