import { Auth } from 'aws-amplify';
import axiosBase from 'axios';

import { getMessage } from '@/common/messages';
/**
 * http通信を行うクラス
 */
import constants from '@/config/constants';
import { i18n } from '@/plugin/i18n';
import router from '@/router';
import store from '@/store/index.js';

const http = axiosBase.create({
  baseURL: constants.API_BASE_URL,
  headers: {
    'Content-Type': 'application/json;charset=UTF8',
    'x-api-key': constants.API_KEY,
    'Accept-Language': i18n.global.locale
  },
  responseType: 'json',
  timeout: constants.API_TIMEOUT
});

http.interceptors.request.use(async config => {
  //未ログインでのアクセス
  if (config.data && config.data.allowNonLoginAccess) {
    return config;
  }

  let token = '';
  await Auth.currentSession()
    .then(session => {
      token = session.getIdToken().getJwtToken();
    })
    .catch(error => {
      if (config.NoLoginAccess || config.data.NoLoginAccess) {
        // ログインチェックを行わない場合
        let userId = store.getters['user/user_id'];
        if (userId) {
          config.headers.nologinuserid = userId;
        }

        return config;
      } else {
        // ログインチェックを行う場合
        console.error('[/src/common/http][interceptors.request]', error);
        // TODO: ログインされていないときの処理を要検討
        alert('ログインされていません。');
        router.push('/login');
      }
    });
  config.headers.Authorization = token;

  return config;
});

http.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response) {
      if (error.response.status == 400) {
        // ビジネスチェックエラー
        throw error.response.data;
      } else if (error.response.status >= 500) {
        // サーバエラー
        // リトライ確認
        if (confirm(getMessage('CONFIRM_SERVER_ERROR_RETRY'))) {
          // リトライ
          if (typeof error.config.data === 'string') {
            error.config.data = JSON.parse(error.config.data);
          }
          return http.request(error.config);
        }
        throw error;
      }
    }
    throw error;
  }
);

export default http;
