const initialData = {
  ableThing: null
};

export default {
  namespaced: true,
  state: {
    ableThing: initialData.ableThing
  },
  getters: {
    ableThing(state) {
      return state.ableThing;
    }
  },
  actions: {
    /**
     * できたことの設定
     */
    setAbleThing({ commit }, ableThing) {
      commit('setAbleThing', ableThing);
    },
    /**
     * ポジヘル情報の初期化
     */
    clearPositiveeqInfo({ commit }) {
      commit('clearPositiveeqInfo', initialData);
    }
  },
  mutations: {
    setAbleThing(state, ableThing) {
      state.ableThing = ableThing;
    },
    clearPositiveeqInfo(state, initialData) {
      for (let key in initialData) {
        state[key] = initialData[key];
      }
    }
  }
};
