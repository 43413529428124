import http from '@/common/http';
import constants from '@/config/constants';
import store from '@/store/index.js';

/**
 * ユーザ利用履歴登録
 * @param {String} screenName 画面名
 * @param {String} contents コンテンツ（ダイアログ等のサブ画面名）
 * @param {Array} tenantId 表示テナントID
 * @param {Array} tenantName 表示テナント名
 * @param {String} action アクション
 * @param {String} actionName アクション名（リンクの内容等）
 * @param {Boolean} login ログインの有無
 */
export const accessLog = (screenName, contents, tenantId, tenantName, action, actionName, login) => {
  const params = {
    screenName: screenName,
    contents: contents,
    tenantId: tenantId,
    tenantName: tenantName,
    action: action,
    actionName: actionName,
    NoLoginAccess: login ? false : true,
    formValue: store.getters['tag/tagKeyValues']
  };
  // 非同期で通信する
  http
    .post(constants.API_USER_USAGE_HISTORY_URI, params)
    .then()
    .catch(() => {
      // エラーは無視
    });
};
