import http from '@/common/http';
import constants from '@/config/constants';
import { isEmpty } from '@/common/validators';

const initialData = {
  chatHistories: null,
  nextKey: null,
  isNoData: false
};

export default {
  namespaced: true,
  state: {
    chatHistories: initialData.chatHistories,
    nextKey: initialData.nextKey,
    isNoData: initialData.isNoData
  },
  getters: {
    chatHistories(state) {
      return state.chatHistories;
    },
    nextKey(state) {
      return state.nextKey;
    },
    isNoData(state) {
      return state.isNoData;
    }
  },
  actions: {
    /**
     * ヒストリー情報の初期化
     */
    clearHistoryList({ commit }) {
      commit('setInitialData', initialData);
    },
    /**
     * チャット履歴取得処理
     * @param params リクエストパラメータ
     */
    async getRequest({ commit }, params) {
      return new Promise((resolve, reject) => {
        let url = constants.API_CHAT_HISTORY_URI;
        if (!isEmpty(params.nextKey)) {
          url = url + '?next_key=' + params.nextKey;
        }
        http
          .get(url)
          .then(response => {
            if (response) {
              commit('setHistoryInfo', response.data);
            }
            resolve();
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  },
  mutations: {
    setHistoryInfo(state, historyInfo) {
      // チャット履歴
      if ('chat_histories' in historyInfo) {
        // 初期設定はfalse
        state.isNoData = false;

        if (!isEmpty(state.chatHistories)) {
          let historySum = new Array();

          // 既存のヒストリー情報を設定
          for (let beseHistory of state.chatHistories) {
            historySum.push(beseHistory);
          }

          // 取得したのヒストリー情報を追加
          for (let getHistory of historyInfo.chat_histories) {
            historySum.push(getHistory);
          }

          // 合算したヒストリ情報を格納
          state.chatHistories = historySum;
        } else {
          // 初回時
          state.chatHistories = historyInfo.chat_histories;
          if (isEmpty(historyInfo.chat_histories)) {
            // データなし時のみ設定
            state.isNoData = true;
          }
        }
      }
      // もっと見る
      if ('next_key' in historyInfo) {
        state.nextKey = historyInfo.next_key;
      } else {
        state.nextKey = null;
      }
    },
    setInitialData(state, initialData) {
      if (isEmpty(initialData)) {
        return;
      }
      if ('chatHistories' in initialData) {
        state.chatHistories = initialData.chatHistories;
      }
      if ('nextKey' in initialData) {
        state.nextKey = initialData.nextKey;
      }
      if ('isNoData' in initialData) {
        state.isNoData = initialData.isNoData;
      }
    }
  }
};
