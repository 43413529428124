import store from '../index';
import { isEmpty } from '@/common/validators';
import constants from '@/config/constants';

const initialState = {
  components: [],
  latestQuestionMessage: null,
  exampleModals: [],
  exampleMessageModals: [],
  columnModals: []
};

export default {
  namespaced: true,
  state: {
    components: [],
    latestQuestionMessage: null,
    exampleModals: [],
    exampleMessageModals: [],
    columnModals: [],
    processing: false,
    showText: false,
    showSelect: false,
    showChoice: false
  },
  getters: {
    components(state) {
      return state.components;
    },
    latestCompNum(state) {
      return state.components.length;
    },
    latestQuestionMessage(state) {
      return state.latestQuestionMessage;
    },
    exampleModals(state) {
      return state.exampleModals;
    },
    exampleMessageModals(state) {
      return state.exampleMessageModals;
    },
    columnModals(state) {
      return state.columnModals;
    },
    showText(state) {
      return state.showText;
    },
    showSelect(state) {
      return state.showSelect;
    },
    showChoice(state) {
      return state.showChoice;
    }
  },
  actions: {
    /**
     * 質問チャットコンポーネント追加
     */
    async addQuestionChatComponent({ dispatch, commit, state }, reqData) {
      if (state.processing) return;
      // 相談チャット応答処理
      commit('beginProcessing');
      await dispatch('chat/request', reqData, { root: true })
        .then(() => {
          // つづきを読むコンポーネントを削除
          this.dispatch('chatComponent/removeNextMoreChatComponent');

          let inputType = store.getters['chat/inputType'];
          let prevInputType = store.getters['chat/prevInputType'];
          let nextStep = reqData.nextStep;

          let waitTime = 0;
          // 前stepのinputTypeによって間の時間を変化させる
          if (prevInputType === 'text' || prevInputType === 'text_repeat') {
            if (reqData.input != null && reqData.input != '' && reqData.input.length < constants.TEXT_THRESHOID) {
              waitTime = constants.TEXT_DELAY_UNDER_TIME;
            } else if (reqData.input != null && reqData.input != '' && reqData.input.length >= constants.TEXT_THRESHOID) {
              waitTime = constants.TEXT_DELAY_OVER_TIME;
            }
          } else if (prevInputType === 'select' || prevInputType === 'select_custom') {
            waitTime = constants.SELECT_DELAY_TIME;
          } else if (prevInputType === 'choice') {
            waitTime = constants.CHOICE_DELAY_TIME;
          }

          // nextStepがstep_endの場合は間の時間を0秒にする
          if (nextStep === 'step_end') {
            waitTime = 0;
          }

          let params = {
            waitTime: waitTime
          };

          // コンポーネントを追加
          this.dispatch('chatComponent/addQuestionBubbleChatComponent', params);
          // ユーザ入力欄を非表示にする
          if (inputType == 'text' || inputType == 'text_repeat') {
            state.showText = false;
          } else if (inputType == 'select' || inputType == 'select_custom') {
            state.showSelect = false;
          } else if (inputType == 'choice') {
            state.showChoice = false;
          }

          // 間を取る処理終了まで待つ
          setTimeout(() => {
            if (inputType === 'read_more') {
              this.dispatch('chatComponent/addNextMoreChatComponent');
            }
            // 例をみるモーダルを追加
            let modal = store.getters['chat/modal'];
            if (modal && modal.type === 'example') {
              this.dispatch('chatComponent/addExample');
            }
            // 例をみる（メッセージ）モーダルを追加
            if (modal && modal.type === 'example_message') {
              this.dispatch('chatComponent/addExampleMessage');
            }
            // コラムモーダルを追加
            if (modal && modal.type === 'column') {
              this.dispatch('chatComponent/addColumn');
            }
            // ユーザ入力欄を表示する
            if (inputType == 'text' || inputType == 'text_repeat') {
              state.showText = true;
            } else if (inputType == 'select' || inputType == 'select_custom') {
              state.showSelect = true;
            } else if (inputType == 'choice') {
              state.showChoice = true;
            }
          }, waitTime);
          // 前stepのinputTypeを保持する
          this.dispatch('chat/setPrevInputType', inputType);
        })
        .catch(error => {
          console.error('[src/store/modules/chatComponent][addQuestionChatComponent]シナリオチャット応答処理エラー：', error);
          return Promise.reject(error);
        });
      commit('endProcessing');
    },
    /**
     * 質問コンポーネントを追加
     */
    addQuestionBubbleChatComponent({ commit }, params) {
      let messages = store.getters['chat/messages'];
      if (isEmpty(messages)) return;
      let message = messages[Math.floor(Math.random() * messages.length)]; // ランダム
      let component = {
        component: 'Question',
        modal: store.getters['chat/modal'],
        currentStep: store.getters['chat/currentStep'],
        message: message,
        waitTime: params.waitTime
      };
      commit('addComponent', component);
      commit('setLatestQuestionMessage', message);
    },
    /**
     * つづきを読むコンポーネントを追加
     */
    addNextMoreChatComponent({ commit }) {
      let component = {
        component: 'NextMore',
        currentStep: store.getters['chat/currentStep'],
        nextStep: store.getters['chat/nextStep']
      };
      commit('addComponent', component);
    },
    /**
     * つづきを読むコンポーネントを削除
     */
    removeNextMoreChatComponent({ commit, state }) {
      let component = state.components.slice(-1)[0];
      if (component != null && component.component === 'NextMore') {
        commit('removeComponent');
      }
    },
    /**
     * 回答コンポーネントを追加
     */
    addAnserBubbleChatComponent({ commit, state }, message) {
      if (state.processing) return;
      commit('beginProcessing');
      let component = {
        component: 'Answer',
        message: message,
        currentStep: store.getters['chat/currentStep']
      };
      commit('addComponent', component);
      commit('endProcessing');
    },
    /**
     * Stateを初期化
     */
    destoryState({ commit }) {
      commit('destoryState');
    },
    /**
     * 例をみるモーダルを追加
     */
    addExample({ commit }) {
      let example = {
        step: store.getters['chat/currentStep'],
        modal: store.getters['chat/modal']
      };
      commit('addExampleModel', example);
    },
    /**
     * 例をみる(メッセージ）モーダルを追加
     */
    addExampleMessage({ commit }) {
      let example = {
        step: store.getters['chat/currentStep'],
        modal: store.getters['chat/modal']
      };
      commit('addExampleMessageModel', example);
    },
    /**
     * コラムモーダルを追加
     */
    addColumn({ commit }) {
      let column = {
        step: store.getters['chat/currentStep'],
        modal: store.getters['chat/modal']
      };
      commit('addColumnModel', column);
    },
    setShowChoice({ commit }, showFlag) {
      commit('setShowChoice', showFlag);
    }
  },
  mutations: {
    addComponent(state, component) {
      state.components.push(component);
    },
    removeComponent(state) {
      state.components.pop();
    },
    setLatestQuestionMessage(state, message) {
      state.latestQuestionMessage = message;
    },
    destoryState(state) {
      for (let key in state) {
        if (Object.prototype.hasOwnProperty.call(initialState, key)) {
          if (Array.isArray(state[key])) {
            state[key].length = initialState[key].length;
          } else {
            state[key] = initialState[key];
          }
        }
      }
    },
    addExampleModel(state, example) {
      state.exampleModals.push(example);
    },
    addExampleMessageModel(state, example) {
      state.exampleMessageModals.push(example);
    },
    addColumnModel(state, column) {
      state.columnModals.push(column);
    },
    beginProcessing(state) {
      state.processing = true;
    },
    endProcessing(state) {
      state.processing = false;
    },
    setShowChoice(state, showFlag) {
      state.showChoice = showFlag;
    }
  }
};
